import { NgIf } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterLink, RouterOutlet } from '@angular/router';
import { ServicemeshFullscreenComponent } from '@helpers/components/fullscreen';
import { ServicemeshLoadingBarComponent } from '@helpers/components/loading-bar';
import {
  ServicemeshHorizontalNavigationComponent,
  ServicemeshNavigationService,
  ServicemeshVerticalNavigationComponent,
} from '@helpers/components/navigation';
import { ServicemeshMediaWatcherService } from '@helpers/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { DropdownOrganizationComponent } from 'app/layout/common/dropdown-organization/dropdown-organization.component';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, takeUntil } from 'rxjs';
import { OrganizationSelectorInputComponent } from '../../../../shared/components/organization-selector-input/organization-selector-input.component';
import { HasRoleDirective } from 'app/core/auth/directives/has-role.directive';
import { SetOrganizationGlobalService } from 'app/shared/services/set-organization-global.service';

@Component({
  selector: 'enterprise-layout',
  templateUrl: './enterprise.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ServicemeshLoadingBarComponent,
    NgIf,
    ServicemeshVerticalNavigationComponent,
    MatButtonModule,
    MatIconModule,
    LanguagesComponent,
    ServicemeshFullscreenComponent,
    SearchComponent,
    ShortcutsComponent,
    MessagesComponent,
    NotificationsComponent,
    UserComponent,
    ServicemeshHorizontalNavigationComponent,
    RouterOutlet,
    QuickChatComponent,
    MatSlideToggleModule,
    DropdownOrganizationComponent,
    RouterLink,
    OrganizationSelectorInputComponent,
    HasRoleDirective,
  ],
})
export class EnterpriseLayoutComponent implements OnInit, OnDestroy {
  private readonly _setGlobalOrganization = inject(SetOrganizationGlobalService);
  private readonly _unsubscribeAll = new Subject<null>();

  isScreenSmall: boolean;
  navigation: Navigation;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _servicemeshMediaWatcherService: ServicemeshMediaWatcherService,
    private readonly _servicemeshNavigationService: ServicemeshNavigationService
  ) {}

  public layoutName;
  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._servicemeshMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._servicemeshNavigationService.getComponent<ServicemeshVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  setOrganizationChange($event): void {
    this._setGlobalOrganization.setOrganization($event.value);
  }
}
