import { v4 as uuidv4 } from 'uuid';
export class ServicemeshMockApiUtils {
  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /***
   * Generate a globally unique id
   */
  static guid(): string {
    return uuidv4();
  }
}
