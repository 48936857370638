import { GraphQLVariables } from '@helpers/services/utils/interfaces';
import { gql } from 'apollo-angular';

export const GET_ORGANIZATION_LIST_INFORMATION_DATA = gql<OrganizationResponse, GraphQLVariables>`
  query getOrganizationListDynamicDropdown(
    $limit: Int!
    $offset: Int!
    $search_by: String = "%"
    $order_by: [OrganizationsOrderBy!]
  ) {
    organizations(
      where: { isActive: { _eq: true }, _or: [{ name: { _ilike: $search_by } }] }
      limit: $limit
      offset: $offset
      orderBy: $order_by
    ) {
      id
      name
    }
    pageInfo: organizationsAggregate(
      where: { isActive: { _eq: true }, _or: [{ name: { _ilike: $search_by } }] }
    ) {
      pages: aggregate {
        count
      }
    }
  }
`;

export interface OrganizationResponse {
  organizations: Organization[];
  pageInfo: Pages;
}

export interface Pages {
  pages: {
    count: number;
  };
}

export interface Organization {
  id: string;
  name: string | null;
}

export interface PageInfo {
  totalElements: number | null;
  currentPage: number;
  pageSize: number;
  lastPage: number;
  orderBy: object | null;
}

export interface OrganizationVars {
  limit: number;
  offset: number;
  search_by: string;
  order_by: object | null;
}
