import { Component, Inject, inject, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatSnackBarAction,
  MatSnackBarActions,
  MatSnackBarLabel,
  MatSnackBarRef,
} from '@angular/material/snack-bar';

import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'snack-bar',
  template: `<span class="rounded-l-md bg-orange-800" matSnackBarLabel>{{ data }} </span>
    <span matSnackBarActions class="bg-orange-800">
      <button
        mat-button
        matSnackBarAction
        color="accent"
        class="mr-2 text-orange-300"
        (click)="snackBarRef.dismissWithAction()"
      >
        {{ actionLabel || 'Close' }}
      </button>
    </span> `,
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
  standalone: true,
  imports: [MatButtonModule, MatSnackBarLabel, MatSnackBarActions, MatSnackBarAction],
})
export class SnackBarComponent {
  snackBarRef = inject(MatSnackBarRef);

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {}

  @Input() message: string; // Input to receive the message
  @Input() actionLabel?: string; // Optional input for action button label
  @Input() actionClick?: () => void; // Optional input for action button click

  dismissWithAction(): void {
    if (this.actionClick) {
      this.actionClick(); // Execute the provided callback if available
    }
    this.snackBarRef.dismissWithAction();
  }
}
