import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import {
  OrganizationByIdService,
  OrganizationSelectorService,
} from './organization-selector-input.service';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatSelect, MatSelectChange, MatSelectModule } from '@angular/material/select';
import { Organization, PageInfo } from './graphql/get-organization-information-list.query';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'organization-selector-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    MatSelectModule,
    MatBadgeModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatInputModule,
    MatTooltipModule,
    MatIconModule,
    AsyncPipe,
  ],
  templateUrl: './organization-selector-input.component.html',
  styleUrls: ['./organization-selector-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationSelectorInputComponent),
      multi: true,
    },
    OrganizationByIdService,
    OrganizationSelectorService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationSelectorInputComponent
  implements OnInit, ControlValueAccessor, OnDestroy, AfterViewInit, OnChanges
{
  @Output() selectionChange = new EventEmitter<MatSelectChange>();
  /** Reference to the search input field */
  @ViewChild('searchSelectInput', { read: ElementRef })
  searchSelectInput: ElementRef;
  @ViewChild('singleSelect') singleSelect: MatSelect;
  @ViewChild(MatSelect) matSelect: MatSelect;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @Input() labelInput = '';
  @Input() placeHolderInput: string;
  @Input() isDisabled = false; //poc
  @Input() isPrivateRecord?: boolean = false; //poc
  @Input() messagePrivateRecord?: string = ''; //poc
  @Input() destroyedSubject: Subject<void>;

  selectListControl = new FormControl('');

  pageSizeOptions: number[] = [10, 15, 30];
  dataSearched = true;
  pageInfo: PageInfo;
  pageIndex = 0; // Index of the current page
  pageSize = 5; // Page size (number of items per page)
  // Data source for MatTable, initialized later
  dataSource: MatTableDataSource<Organization>;

  isLoadingDropdown$ = this._dropDownDynamicService.loading;
  searchForm!: FormGroup;

  dataItemList: unknown[] = [];
  private readonly _unsubscribeAll = new Subject<void>();
  private _required = false;

  constructor(
    @Self()
    private readonly _dropDownDynamicService: OrganizationSelectorService,
    @Self()
    private readonly _dropDownOrgByIdDynamicService: OrganizationByIdService,
    private readonly _formBuilder: FormBuilder
  ) {
    this.searchForm = this._formBuilder.group({
      searchBy: [''],
    });
  }

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  ngOnInit(): void {
    this.initialData();
    this.loadOrganizations('');
    // Subscribe to page change event
    this._dropDownDynamicService.pageChanged$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((pageInfo: PageInfo) => {
        this.pageInfo = pageInfo;
      });
  }

  ngAfterViewInit(): void {
    // when the select dropdown panel is opened or closed
    this.singleSelect.openedChange
      .pipe(takeUntil(this.destroyedSubject ? this.destroyedSubject : this._unsubscribeAll))
      .subscribe((opened): void => {
        if (opened) {
          // focus the search field when opening
          this._focus();
          this.loadOrganizations('');
          this.initialData();
          if (this.singleSelect.value !== '' && this.singleSelect.value !== null) {
            this._dropDownDynamicService.restartPagination();
            this.loadOrganizations('');
            this.initialData();

            this.placeHolderInput = this.singleSelect.value?.name;
          }
        } else {
          // clear it when closing
          this._dropDownDynamicService.restartPagination();
          // this.loadOrganizations('');
          this.initialData();
          this.writeValue(this.singleSelect.value);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.currentValue) {
      return;
    } else {
      this.initialData();
      this._dropDownDynamicService.pageChanged$
        .pipe(takeUntil(this.destroyedSubject ? this.destroyedSubject : this._unsubscribeAll))
        .subscribe((pageInfo: PageInfo) => {
          this.pageInfo = pageInfo;
        });
    }
  }

  itemsPerPage(pageEvent: PageEvent): void {
    this.handlePageInfoAndEmit(pageEvent);
  }

  handlePageInfoAndEmit(pageEvent: PageEvent): void {
    const pageInfo: PageInfo = {
      totalElements: this.pageInfo.totalElements,
      currentPage: pageEvent.pageIndex,
      pageSize: pageEvent.pageSize,
      lastPage: this.pageInfo.lastPage,
      orderBy: this.pageInfo.orderBy,
    };
    this._dropDownDynamicService.onPageCursorChanged(pageInfo);

    this.initialData();
  }

  resetList(): void {
    this.searchForm.reset();
    this.searchForm.controls['searchBy'].setValue('');
    this.pageIndex = 0; // Reset page index
    this._dropDownDynamicService.restartPagination();
    this.initialData();
  }

  // Method to handle sorting
  sortData(sort: Sort): void {
    // Update PageInfo object for sorting
    const pageInfo: PageInfo = {
      totalElements: this.pageInfo.totalElements,
      currentPage: 0,
      pageSize: this.pageInfo.pageSize,
      orderBy: this.handleOrderBy(sort, 'email'),
      lastPage: this.pageInfo.lastPage,
    };
    this._dropDownDynamicService.onPageCursorChanged(pageInfo); // Update PageInfo in service

    // Verify if search gives no results to avoid continue and get an error
    if (this._dropDownDynamicService.data().organizations.length === 0) {
      return;
    }

    // Reload courses with search query after sorting
    this.loadOrganizations(this.searchForm.get('searchBy').value);
  }

  // Method to handle sorting and construct orderBy object
  handleOrderBy(sort: Sort, mainSortHeader: string): object {
    // Determine the column to sort by
    const column = sort.active === `${mainSortHeader}` ? `${mainSortHeader}` : sort.active;
    // Determine the sorting direction and convert it to uppercase
    const direction = sort.direction.toUpperCase();
    // Construct the orderBy object based on the column and direction
    const orderBy = {};
    orderBy[column] = direction === '' ? null : direction; // If direction is empty, set orderBy[column] to null
    return orderBy; // Return the constructed orderBy object
  }

  clearSearchAndFilter(): void {
    this.searchForm.reset();
    this.searchForm.controls['searchBy'].setValue('');
    this.initialData();
  }

  stopInTemplatePropagationAndDefault($event): void {
    this.stopPropagationAndDefault($event);
  }

  initialData(): void {
    this.searchForm
      .get('searchBy')
      .valueChanges.pipe(takeUntil(this._unsubscribeAll), debounceTime(300), distinctUntilChanged())
      .subscribe((searchText: string) => {
        this.pageIndex = 0; // Reset page index when search changes
        this.loadOrganizations(searchText);
      });
  }

  // ControlValueAccessor Implementation
  onChange: unknown = () => {};
  onTouched: unknown = () => {};

  registerOnChange(fn: unknown): void {
    this.selectListControl.valueChanges
      .pipe(takeUntil(this.destroyedSubject ? this.destroyedSubject : this._unsubscribeAll))
      .subscribe(fn);
    this.onChange = fn;
  }

  registerOnTouched(fn: unknown): void {
    this.onTouched = fn;
  }

  writeValue(value = Object.assign({})): void {
    if (typeof value === 'object') {
      // Assuming that the ID is stored in a property named 'id' within the object
      if (value && value.id) {
        // Convert the object's ID into a UUID
        value = value.id;
      } else {
        // If the object doesn't have a valid ID, set the value to null
        value = null;
      }
    }
    if (value) {
      if (this.checkIfValidUUID(value)) {
        this._dropDownOrgByIdDynamicService
          .getOrganizationById(value)
          .subscribe((response): void => {
            value = response.data.organizations[0];
            this.dataItemList = [value];
            this.selectListControl.setValue(value, {
              emitEvent: true,
            });
          });
      } else {
        this.selectListControl.setValue(value, { emitEvent: true });
        this.dataItemList = [value];
      }
    } else {
      this.selectListControl.reset('');
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /***
   * Focuses the search input field
   * @private
   */
  _focus(): void {
    if (!this.searchSelectInput) {
      return;
    }
    // save and restore scrollTop of panel, since it will be reset by focus()
    // note: this is hacky
    const panel = this.singleSelect.panel.nativeElement;
    const scrollTop = panel.scrollTop;

    // focus
    this.searchSelectInput.nativeElement.focus();

    panel.scrollTop = scrollTop;
  }

  /***
   * Resets the current search value
   * @param {boolean} focus whether to focus after resetting
   * @private
   */
  _reset(focus?: boolean): void {
    if (!this.searchSelectInput) {
      return;
    }
    this.searchSelectInput.nativeElement.value = '';
    this.onChange = '';
    if (focus) {
      this._focus();
    }
  }

  /***
   * Handles the key down event with MatSelect.
   * Allows e.g. selecting with enter key, navigation with arrow keys, etc.
   * @param {KeyboardEvent} event
   * @private
   */
  _handleKeydown(event: KeyboardEvent): void {
    if (event.code === 'Space') {
      // do not propagate spaces to MatSelect, as this would select the currently active option
      event.stopPropagation();
    }
  }

  stopPropagationAndDefault = (event: Event): void => {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
  };

  checkIfValidUUID = (str): boolean => {
    // Regular expression to check if string is a valid UUID
    const regexExp =
      /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

    return regexExp.test(str);
  };

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(_index: number, item = Object.assign({})): string {
    return item?.name;
  }

  loadOrganizations(searchQuery?: string): void {
    const orderBy = this.pageInfo.orderBy;
    // Load users based on current search and pagination settings
    this._dropDownDynamicService
      .getOrganizationForDropdown({
        query: searchQuery, // Search query
        limit: this.pageInfo.pageSize, // Limit of users per page
        page: this.pageInfo.currentPage + 1, // Page index (starting from 1)
        order: orderBy,
      })
      .subscribe((result): void => {
        // Update PageInfo object
        const pageInfo: PageInfo = {
          totalElements: result?.data.pageInfo.pages.count,
          currentPage: this._dropDownDynamicService.pageInfo.currentPage,
          pageSize: this._dropDownDynamicService.pageInfo.pageSize,
          orderBy: this.pageInfo.orderBy,
          lastPage:
            result?.data.pageInfo.pages.count - this._dropDownDynamicService.pageInfo.pageSize,
        };
        this._dropDownDynamicService.onPageCursorChanged(pageInfo); // Update PageInfo in service
        this.dataSource = new MatTableDataSource<Organization>(result.data.organizations); // Update data source
        // Update user list and total results
        this.dataItemList = this.dataSource.data; //user list
        this._dropDownDynamicService._totalResults.next(result.data.pageInfo.pages.count); // Update total results count
      });
  }

  // Method to handle page change event
  onPageChange(event: PageEvent): void {
    // Update PageInfo object with new page information
    const pageInfo: PageInfo = {
      totalElements: this.pageInfo.totalElements,
      currentPage: event.pageIndex,
      pageSize: event.pageSize,
      orderBy: this.pageInfo.orderBy,
      lastPage: this.pageInfo.lastPage,
    };
    this._dropDownDynamicService.onPageCursorChanged(pageInfo); // Update PageInfo in service
    this.loadOrganizations(this.searchForm.get('searchBy').value); // Load courses with search query
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this._unsubscribeAll.unsubscribe();
    this.resetList();
  }
}
