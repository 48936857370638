import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

export const appRoutes: Route[] = [
  // Redirect empty path to '/apps/academy'
  { path: '', pathMatch: 'full', redirectTo: 'apps/academy' },

  // Redirect signed-in user to the '/apps/academy'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: 'apps/academy',
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-in',
        loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
      },
      {
        path: 'sso',
        loadChildren: () => import('app/modules/auth/single-sign-on/single-sign-on.routes'),
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
      },
    ],
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      // Apps
      {
        path: 'apps',
        children: [
          {
            path: 'academy',
            loadChildren: () => import('app/modules/admin/apps/academy/academy.routes'),
          },
          {
            path: 'admin-academy',
            loadChildren: () => import('app/modules/admin/apps/admin-academy/admin-academy.routes'),
          },
        ],
      },
      {
        path: 'unauthorized',
        loadChildren: () => import('app/modules/auth/unauthorized/unauthorized.routes'),
      },
      // 404 & Catch all
      {
        path: '404-not-found',
        pathMatch: 'full',
        redirectTo: 'apps/academy',
      },
    ],
  },
  { path: '**', redirectTo: '404-not-found' },
];
