import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { AuthService } from './core/auth/auth.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet />',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, CommonModule],
})
export class AppComponent implements OnInit {
  private readonly _keycloakService = inject(KeycloakService);
  private readonly _authService = inject(AuthService);

  ngOnInit(): void {
    // Subscribe to Keycloak events through the keycloakEvents$ observable
    this._keycloakService.keycloakEvents$.subscribe((event) => {
      // Check if the event type is 'OnTokenExpired'
      if (event.type == KeycloakEventType.OnTokenExpired) {
        // Call the updateToken method of the KeycloakService to refresh the token
        this._keycloakService.updateToken();
      }
      // Check if the event type is 'OnAuthError'
      if (event.type == KeycloakEventType.OnAuthError) {
        // Call the logout method of the KeycloakService, passing the current URL to redirect after logging out
        this._authService.signOut();
      }
    });
  }
}
