import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Organization } from '../components/organization-selector-input/graphql/get-organization-information-list.query';

@Injectable({
  providedIn: 'root',
})
export class SetOrganizationGlobalService {
  private readonly roleSource = new BehaviorSubject<string>('user'); // or 'sysadmin', 'orgadmin'
  readonly organizationSource = new BehaviorSubject<Organization | null>(null);
  private readonly _userEnrolledCourseFilter = new BehaviorSubject<boolean>(false);
  userEnrolledCourseFilter$ = this._userEnrolledCourseFilter.asObservable();

  setOrganization$ = this.organizationSource.asObservable();
  userRole$ = this.roleSource.asObservable();

  setOrganization(org: Organization): void {
    this.organizationSource.next(org);
  }

  setRole(role: string): void {
    this.roleSource.next(role);
  }

  setUserEnrolledCourseFilter(value: boolean): void {
    this._userEnrolledCourseFilter.next(value);
  }

  get selectedOrganization(): Organization {
    return this.organizationSource.value;
  }
}
