import { environment } from 'environments/environment';
import { KeycloakService } from 'keycloak-angular';

export function initializeKeycloak(keycloak: KeycloakService): unknown {
  return () =>
    keycloak.init({
      config: {
        url: environment.keyCloak.urlAuthKeycloak,
        realm: environment.keyCloak.realmKeycloak,
        clientId: environment.keyCloak.clientId,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
        flow: 'standard',
        scope: environment.keyCloak.scope,
        redirectUri: window.location.origin,
        refreshToken: environment.keyCloak.silentRenewUrl,
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: ['./assets'],
    });
}
