import { computed, signal } from '@angular/core';
import { ApolloError } from '@apollo/client/core';
import { catchError, tap } from 'rxjs';
import { ResultMutation, ResultQuery } from './type-graphql.service';

// Interface defining the state of the signal
export interface SignalState<T> {
  data: T | null; // Data retrieved from the query or mutation
  loading: boolean; // Loading state
  error: string | null; // Error message if any
}
/**
 * Abstract class for handling signals and store state only for simple CRUD services
 *
 * Wrap the query and mutation results with the query and mutation methods respectively
 *
 * @template T - The type of the data, query and mutation must return the same type
 */
export abstract class AbstractSignalStoreService<T> {
  // Initialize state signal
  private readonly _state = signal<SignalState<T>>({
    data: null, // Initialize data as null
    loading: false, // Set loading state to false
    error: null, // Set error message to null
  });

  // Computed property to get the current data state
  data = computed(() => this._state().data);

  // Computed property to get the current loading state
  loading = computed(() => this._state().loading);

  // Computed property to get the current error state
  error = computed(() => this._state().error);

  // Method to handle query results
  protected query(result: ResultQuery<T>): ResultQuery<T> {
    // Set loading state to true
    this.setLoading(true);
    return result.pipe(
      // Update data and set loading state to false on successful query
      tap(({ data }) => this.setData(data)),
      // Set error and throw error on query failure
      catchError((error: ApolloError) => {
        this.setError(error.message);
        throw error;
      })
    );
  }

  // Method to handle mutation results
  protected mutation(result: ResultMutation<T>): ResultMutation<T> {
    // Set loading state to true
    this.setLoading(true);
    return result.pipe(
      // Update data and set loading state to false on successful mutation
      tap(({ data }) => this.setData(data ?? null)),
      // Set error and throw error on mutation failure
      catchError((error: ApolloError) => {
        this.setError(error.message);
        throw error;
      })
    );
  }

  // Method to update data state
  protected setData(data: T | null): void {
    this._state.update((state: SignalState<T>) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }));
  }

  // Method to update loading state
  private setLoading(loading: boolean): void {
    this._state.update((state: SignalState<T>) => ({
      ...state,
      loading,
    }));
  }

  // Method to update error state
  private setError(error: string): void {
    this._state.update((state: SignalState<T>) => ({
      ...state,
      data: null,
      loading: false,
      error,
    }));
  }
}
