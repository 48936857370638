<mat-form-field
  class="dynamic-dropdown w-full"
  appearance="outline"
  [ngClass]="{'disabled': isDisabled}"
>
  <mat-label>{{labelInput}} *</mat-label>
  <mat-select
    #singleSelect
    (keydown)="_handleKeydown($event)"
    [required]="required"
    (selectionChange)="selectionChange.emit($event)"
    [formControl]="selectListControl"
    placeholder="{{ placeHolderInput }}"
  >
    <form [formGroup]="searchForm" class="w-full p-3">
      <mat-form-field
        class="servicemesh-mat-dense servicemesh-mat-no-subscript w-100-p sticky top-0 z-9999 bg-white px-1 pt-1"
      >
        <input
          #searchSelectInput
          (keydown)="_handleKeydown($event)"
          matInput
          placeholder="Search"
          formControlName="searchBy"
          name="searchBy"
          [autocomplete]="'off'"
        />
        <mat-icon matPrefix>search</mat-icon>
        <button
          type="button"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSearchAndFilter()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
    <mat-progress-bar
      *ngIf="isLoadingDropdown$()"
      mode="indeterminate"
      color="primary"
    ></mat-progress-bar>
    <mat-option
      (keydown)="stopInTemplatePropagationAndDefault($event)"
      (click)="clearSearchAndFilter()"
      value=""
    >
    </mat-option>
    <ng-container *ngFor="let item of dataItemList; trackBy: trackByFn">
      <mat-option
        [disabled]="!!(isLoadingDropdown$())"
        [value]="item"
        (keydown)="stopInTemplatePropagationAndDefault($event)"
      >
        {{ item?.name }}
      </mat-option>
    </ng-container>
    <ng-container>
      <mat-paginator
        class="servicemesh-mat-dense servicemesh-mat-no-subscript w-50-p sticky bottom-0 z-9999 bg-white pt-1"
        [length]="pageInfo?.totalElements"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageInfo?.currentPage"
        [pageSize]="pageInfo?.pageSize"
        (page)="itemsPerPage($event)"
        [hidePageSize]="true"
        showFirstLastButtons
        [hidePageSize]="true"
      >
      </mat-paginator>
    </ng-container>
  </mat-select>
  <mat-button
    *ngIf="isPrivateRecord"
    matSuffix
    (click)="tooltipForClassification.toggle()"
    (keyup)="tooltipForClassification.toggle()"
  >
    <mat-icon
      #tooltipForClassification="matTooltip"
      matTooltip="{{messagePrivateRecord}}"
      [matTooltipClass]="'bg-blue-900/90 text-md font-semibold'"
      >info</mat-icon
    >
  </mat-button>
</mat-form-field>
