import { inject, Injectable } from '@angular/core';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { GraphQLVariables, PageInfo } from '@helpers/services/utils/interfaces';
import { BehaviorSubject } from 'rxjs';
import {
  GET_ORGANIZATION_LIST_INFORMATION_DATA,
  OrganizationResponse,
} from './graphql/get-organization-information-list.query';
import {
  AbstractSignalStoreService,
  ResultQuery,
  TypedGraphQlService,
} from '@helpers/services/utils';
import { environment } from 'environments/environment';
import {
  GET_ORGANIZATION_BY_ID,
  OrganizationResponseById,
} from './graphql/get-organization-information-by-id.query';

export const PAGE_ORG_SIZE_INIT = 5;

@Injectable({
  providedIn: 'root',
})
export class OrganizationSelectorService extends AbstractSignalStoreService<OrganizationResponse> {
  private readonly graphService = inject(TypedGraphQlService);
  private readonly _orderBy = { name: 'ASC' };
  private readonly _applyPolicy: WatchQueryFetchPolicy = 'cache-and-network';

  _totalResults = new BehaviorSubject<number>(0);
  totalResults$ = this._totalResults.asObservable();

  /* Pagination */
  pageInfo: PageInfo = {
    totalElements: null,
    currentPage: 0,
    pageSize: 5,
    lastPage: null,
    orderBy: this._orderBy,
  };

  pageChangedSubject = new BehaviorSubject<PageInfo>(this.pageInfo);
  pageChanged$ = this.pageChangedSubject.asObservable();

  constructor() {
    super();
    this.pageChanged$.subscribe((pageInfo: PageInfo) => {
      this.pageInfo = pageInfo;
    });
  }

  onPageCursorChanged(pageInfo: PageInfo): void {
    this.pageChangedSubject.next(pageInfo);
  }

  restartPagination(): void {
    const pageInfo: PageInfo = {
      totalElements: null,
      currentPage: 0,
      pageSize: 5,
      orderBy: this._orderBy,
      lastPage: 0,
    };

    this.onPageCursorChanged(pageInfo);
  }

  getOrganizationForDropdown({
    query = '',
    limit = 5,
    page = 1,
    order = this._orderBy,
  }: {
    query?: string;
    limit?: number;
    page?: number;
    order?: object;
  }): ResultQuery<OrganizationResponse> {
    const variables: GraphQLVariables = {
      limit,
      offset: (page - 1) * limit,
      search_by: `%${query}%`,
      order_by: order,
    };

    return this.query(
      this.graphService.getQueryResults<OrganizationResponse>(
        environment.graphqlServerName.academy_name_endpoint,
        GET_ORGANIZATION_LIST_INFORMATION_DATA,
        variables,
        undefined,
        this._applyPolicy
      )
    );
  }
}

@Injectable({ providedIn: 'root' })
export class OrganizationByIdService extends AbstractSignalStoreService<OrganizationResponseById> {
  private readonly graphService = inject(TypedGraphQlService);
  private readonly _applyPolicy: WatchQueryFetchPolicy = 'network-only';

  getOrganizationById(organizationId: string): ResultQuery<OrganizationResponseById> {
    const variables: GraphQLVariables = {
      id: organizationId,
    };

    return this.query(
      this.graphService.getQueryResults<OrganizationResponseById>(
        environment.graphqlServerName.academy_name_endpoint,
        GET_ORGANIZATION_BY_ID,
        variables,
        undefined,
        this._applyPolicy
      )
    );
  }
}
