import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  AbstractSignalStoreService,
  ResultQuery,
  TypedGraphQlService,
} from '@helpers/services/utils';
import {
  GET_USER_BY_KEYCLOAK_ID,
  UserInformationResponse,
} from './graphql/get-user-id-and-id-organization.query';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { GraphQLVariables } from '@helpers/services/utils/interfaces';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class UserService extends AbstractSignalStoreService<UserInformationResponse> {
  private readonly graphService = inject(TypedGraphQlService);
  private _applyPolicy: WatchQueryFetchPolicy = 'cache-and-network';

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {
    super();
  }

  /**
   * Get lesson by id
   * We use the .set function from signals
   */
  getUserInformationByHasura(keyCloakProfileId: string): ResultQuery<UserInformationResponse> {
    const variables: GraphQLVariables = {
      keyCloakProfileId,
    };

    return this.query(
      this.graphService.getQueryResults<UserInformationResponse>(
        environment.graphqlServerName.academy_name_endpoint,
        GET_USER_BY_KEYCLOAK_ID,
        variables,
        undefined,
        this._applyPolicy
      )
    );
  }
}
