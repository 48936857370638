import { Directive, inject, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '../auth.service';

@Directive({
  selector: '[appHasRole]',
  standalone: true,
})
// This directive controls the visibility of elements based on the user's roles.
// It uses the AuthService to check if the user has the required roles.
export class HasRoleDirective implements OnInit {
  @Input() appHasRole!: string[]; // Input array of roles required to access the element
  isVisible = false; // Flag to track the visibility of the element
  private _authService = inject(AuthService); // Injecting the AuthService

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<unknown>
  ) {}

  // On initialization, checks if the user has the required roles and controls the visibility of the element accordingly.
  async ngOnInit(): Promise<void> {
    try {
      // Check if the user has the required roles
      const hasRole = await this._authService.roleMatch(this.appHasRole);
      if (!hasRole) {
        // If the user doesn't have the required roles, clear the view container
        this.viewContainerRef.clear();
        return;
      }

      // Toggle the visibility of the element based on the flag
      if (!this.isVisible) {
        // If the element is not visible, create an embedded view in the view container
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        // If the element is visible, clear the view container
        this.isVisible = false;
        this.viewContainerRef.clear();
      }
    } catch (error) {
      // Log any error that occurs during role checking and clear the view container
      console.error('Error checking user roles:', error);
      this.viewContainerRef.clear();
    }
  }
}

// note: how to use in html like this: *appHasRole="['orgadmin']"
