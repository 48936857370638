import { gql } from 'apollo-angular';
import { Organization } from './get-organization-information-list.query';
import { GraphQLVariables } from '@helpers/services/utils/interfaces';

export const GET_ORGANIZATION_BY_ID = gql<OrganizationResponseById, GraphQLVariables>`
  query getOrganizationDataByIdDynamicDropdown($id: uuid) {
    organizations(where: { isActive: { _eq: true }, id: { _eq: $id } }) {
      id
      name
    }
  }
`;

export interface OrganizationResponseById {
  organizations: Organization;
}
