import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { environment } from 'environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../user/user.types';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly _keycloak = inject(KeycloakService);
  private readonly isLoggedInSubject = new BehaviorSubject<boolean>(false);
  private readonly userBehaviorSubject = new BehaviorSubject<User>(Object.assign({}));
  userBehaviorSubject$ = this.userBehaviorSubject.asObservable();

  /**
   * Constructor
   */
  constructor(
    private _httpClient: HttpClient,
    private _userService: UserService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Forgot password
   *
   * @param email
   */
  forgotPassword(email: string): Observable<unknown> {
    return this._httpClient.post('api/auth/forgot-password', email);
  }

  /**
   * Reset password
   *
   * @param password
   */
  resetPassword(password: string): Observable<unknown> {
    return this._httpClient.post('api/auth/reset-password', password);
  }

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(): void {
    this._keycloak.login().then();
  }

  /***
   * Sign out
   **/
  signOut(): void {
    this._keycloak.logout(environment.keyCloak.postLogoutRedirectUri);
  }

  /**
   * Sign up
   *
   * @param user
   */
  signUp(user: {
    name: string;
    email: string;
    password: string;
    company: string;
  }): Observable<unknown> {
    return this._httpClient.post('api/auth/sign-up', user);
  }

  /**
   * Unlock session
   *
   * @param credentials
   */
  unlockSession(credentials: { email: string; password: string }): Observable<unknown> {
    return this._httpClient.post('api/auth/unlock-session', credentials);
  }

  getUserInformation(): Promise<KeycloakProfile> {
    return this._keycloak.getKeycloakInstance().loadUserProfile();
  }

  isLoggedIn(): Observable<boolean> {
    const resultToLogin = this._keycloak.isLoggedIn();
    this.isLoggedInSubject.next(resultToLogin);
    return this.isLoggedInSubject.asObservable();
  }

  getUsername(): string {
    return this._keycloak.getUsername();
  }

  getUserEmail(): string {
    return this._keycloak.getKeycloakInstance().profile.email;
  }

  getUserKeycloakId(): string {
    let userId;
    this._keycloak.loadUserProfile().then((user) => {
      userId = user.id;
    });
    return userId;
  }

  /**
   * Asynchronously checks if the user has any of the specified roles.
   * @param allowedRoles Array of roles allowed for the user.
   * @returns A Promise that resolves to true if the user has any of the allowed roles, false otherwise.
   */
  async roleMatch(allowedRoles: string[]): Promise<boolean> {
    try {
      // Load user profile assuming there is a synchronous method available to do so
      const userProfile = await this._keycloak.loadUserProfile();
      const userAttributes = userProfile.attributes;
      // Extract user roles from the profile
      const academyRole = userAttributes.academy_spa_role as string[];

      // Combine defaultRole and academyRole arrays into a single array of roles
      const userRoles = [...academyRole];

      // Check if the user has any of the allowed roles
      for (const role of allowedRoles) {
        if (userRoles.includes(role)) {
          return true; // Return true if an allowed role is found
        }
      }
      return false; // Return false if no allowed role is found
    } catch (error) {
      // Throw an error if there's an issue loading the user profile
      throw new Error('Error loading user profile:', error);
    }
  }
}
