<!-- Loading bar -->
<servicemesh-loading-bar></servicemesh-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
  <servicemesh-vertical-navigation
    class="dark bg-gray-900 print:hidden"
    [mode]="'over'"
    [name]="'mainNavigation'"
    [navigation]="navigation.default"
    [opened]="false"
  >
    <!-- Navigation header hook -->
    <ng-container servicemeshVerticalNavigationContentHeader>
      <!-- Logo -->
      <div class="flex h-20 items-center px-8 pt-6">
        <a aria-label="home" [routerLink]="['/']">
          <img
            routerLinkActive="router-link-active"
            class="w-24"
            src="assets/images/logo/logo-academy.png"
            alt="Elecate Academy"
          />
        </a>
      </div>
    </ng-container>
  </servicemesh-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col items-center bg-gray-200 dark:bg-card">
  <!-- Header -->
  <div
    class="relative z-49 flex h-20 w-full flex-0 flex-col justify-center overflow-hidden shadow dark:shadow-none print:hidden"
  >
    <!-- Top bar -->
    <div
      class="dark relative flex w-full flex-auto justify-center bg-[#0F172A] px-4 dark:bg-gray-900 md:px-8"
    >
      <div class="flex h-16 w-full max-w-360 items-center sm:h-20">
        <!-- Logo -->
        <ng-container *ngIf="!isScreenSmall" />

        <div class="flex items-center">
          <a aria-label="home" [routerLink]="['/']">
            <img class="w-24" src="assets/images/logo/logo-academy.png" alt="Logo image" />
          </a>
        </div>

        <!-- Navigation toggle button -->
        <!-- <ng-container *ngIf="isScreenSmall">
          <button
            title="mainNavigation"
            type="button"
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')"
          >
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
          </button>
        </ng-container> -->

        <!-- Components -->
        <div class="ml-auto flex items-center pl-2 sm:space-x-2 md:space-x-0.5 lg:space-x-1">
          <organization-selector-input
            *appHasRole="['sysadmin']"
            class="mr-4 mt-4 w-72"
            ngDefaultControl
            [labelInput]="'Select Organization'"
            [required]="false"
            (selectionChange)="setOrganizationChange($event)"
          >
          </organization-selector-input>
          <button title="home" type="button" mat-icon-button [routerLink]="'./'">
            <span class="relative">
              <mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
            </span>
          </button>
          <servicemesh-fullscreen class="hidden md:block"></servicemesh-fullscreen>
          <!-- <search [appearance]="'bar'"></search> -->
          <user></user>
        </div>
      </div>
    </div>
  </div>

  <!-- Content -->
  <div class="flex w-full flex-auto justify-center">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.-->
    <!--Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="bg-card relative z-49 flex w-full flex-0 justify-center border-t px-6 md:px-8 print:hidden"
  >
    <div class="flex h-14 w-full items-center sm:h-20">
      <span class="text-secondary font-medium">Elecate &copy; {{ currentYear }}</span>
    </div>
  </div>
</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->
