import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ENVIRONMENT_INITIALIZER,
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  Provider,
} from '@angular/core';
import { MATERIAL_SANITY_CHECKS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { SERVICEMESH_MOCK_API_DEFAULT_DELAY, mockApiInterceptor } from '@helpers/lib/mock-api';
import { ServicemeshConfig } from '@helpers/services/config';
import { SERVICEMESH_CONFIG } from '@helpers/services/config/config.constants';
import { ServicemeshConfirmationService } from '@helpers/services/confirmation';
import {
  servicemeshLoadingInterceptor,
  ServicemeshLoadingService,
} from '@helpers/services/loading';
import { ServicemeshMediaWatcherService } from '@helpers/services/media-watcher';
import { ServicemeshPlatformService } from '@helpers/services/platform';
import { ServicemeshSplashScreenService } from '@helpers/services/splash-screen';
import { GraphqlHostService, ServicemeshUtilsService } from '@helpers/services/utils';

export type ServicemeshProviderConfig = {
  mockApi?: {
    delay?: number;
    services?: unknown[];
  };
  servicemesh?: ServicemeshConfig;
};

/**
 * Servicemesh provider
 */
export const provideServicemesh = (
  config: ServicemeshProviderConfig
): Array<Provider | EnvironmentProviders> => {
  // Base providers
  const providers: Array<Provider | EnvironmentProviders> = [
    {
      // Disable 'theme' sanity check
      provide: MATERIAL_SANITY_CHECKS,
      useValue: {
        doctype: true,
        theme: false,
        version: true,
      },
    },
    {
      // Use the 'fill' appearance on Angular Material form fields by default
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      },
    },
    {
      provide: SERVICEMESH_MOCK_API_DEFAULT_DELAY,
      useValue: config?.mockApi?.delay ?? 0,
    },
    {
      provide: SERVICEMESH_CONFIG,
      useValue: config?.servicemesh ?? {},
    },

    importProvidersFrom(MatDialogModule),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(ServicemeshConfirmationService),
      multi: true,
    },

    provideHttpClient(withInterceptors([servicemeshLoadingInterceptor])),
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(ServicemeshLoadingService),
      multi: true,
    },

    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(ServicemeshMediaWatcherService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(ServicemeshPlatformService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(ServicemeshSplashScreenService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(ServicemeshUtilsService),
      multi: true,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      useValue: () => inject(GraphqlHostService),
      multi: true,
    },
  ];

  // Mock Api services
  if (config?.mockApi?.services) {
    providers.push(provideHttpClient(withInterceptors([mockApiInterceptor])), {
      provide: APP_INITIALIZER,
      deps: [...config.mockApi.services],
      useFactory: () => (): unknown => null,
      multi: true,
    });
  }

  // Return the providers
  return providers;
};
