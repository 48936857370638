import { gql } from 'apollo-angular';
import { GraphQLVariables } from '@helpers/services/utils/interfaces';

export const GET_USER_BY_KEYCLOAK_ID = gql<UserInformationResponse, GraphQLVariables>`
  query getUserInformation($keyCloakProfileId: uuid) {
    users(where: { keycloakId: { _eq: $keyCloakProfileId } }) {
      userId: id
      organizationId
      organization {
        id
        name
      }
    }
  }
`;

export interface UserInformationResponse {
  users: {
    userId: string | null;
    organizationId: string | null;
    organization: OrganizationInUser[] | null;
  };
}

export interface OrganizationInUser {
  id: string | null;
  name: string | null;
}
