import { Injectable, inject } from '@angular/core';
import {
  ApolloQueryResult,
  DocumentNode,
  FetchResult,
  TypedDocumentNode,
  WatchQueryFetchPolicy,
} from '@apollo/client/core';
import { Apollo, MutationResult } from 'apollo-angular';
import { Observable, catchError } from 'rxjs';
import { GraphQLVariables } from './interfaces';
import { OptionalHeaderContext } from './interfaces/context.interface';

import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@helpers/components/snack-bar/snack-bar.component';

export type ResultQuery<T> = Observable<ApolloQueryResult<T>>;
export type ResultMutation<T> = Observable<MutationResult<T>>;
export type ResultSubscription<T> = Observable<FetchResult<T>>;

@Injectable({ providedIn: 'root' })
export class TypedGraphQlService {
  // Inject ApolloClient
  private readonly apollo = inject(Apollo);
  private readonly snackBar = inject(MatSnackBar);

  // Function to get results from a GraphQL query
  getQueryResults<T>(
    serverName: string,
    query: TypedDocumentNode<T, GraphQLVariables>,
    variables: GraphQLVariables = {},
    optionalContext?: OptionalHeaderContext,
    fetchPolicy?: WatchQueryFetchPolicy
  ): ResultQuery<T> {
    // Extract context from optional context, or set to empty object
    const context = optionalContext && optionalContext.context ? optionalContext.context : {};

    // Execute the query using Apollo Client and return the result as an observable
    return this.apollo
      .use(serverName)
      .watchQuery<T, GraphQLVariables>({
        fetchPolicy,
        query,
        variables,
        context,
      })
      .valueChanges.pipe(
        catchError((error: Error) => {
          // Log error and throw authorization error if necessary
          console.log({ error });
          if (error.message.includes('Missing Authorization header')) {
            throw new Error('Authorization error');
          }
          this.snackBar.openFromComponent(SnackBarComponent, {
            data: 'An unexpected error has occurred. Please try again later or contact your administrator.',
            verticalPosition: 'top',
          });
          throw error;
        })
      );
  }

  // Function to get results from a GraphQL mutation
  getMutationResults<T>(
    serverName: string,
    mutation: DocumentNode | TypedDocumentNode<T, GraphQLVariables>,
    variables: GraphQLVariables = {},
    optionalContext?: OptionalHeaderContext
  ): ResultMutation<T> {
    // Extract context from optional context, or set to empty object
    const context = optionalContext && optionalContext.context ? optionalContext.context : {};

    // Execute the mutation using Apollo Client and return the result as an observable
    return this.apollo
      .use(serverName)
      .mutate<T, GraphQLVariables>({
        mutation,
        variables,
        context,
      })
      .pipe(
        catchError((error: Error) => {
          // Extract mutation name from the first definition
          const definition = mutation.definitions[0] as { name: { value: string } };
          const dest = definition.name.value;

          // Throw error with message and mutation name
          throw new Error(`${error.message}, dest: ${dest}`);
        })
      );
  }

  // Function to get results from a GraphQL subscription
  getSubscriptionResults<T>(
    serverName: string,
    query: DocumentNode | TypedDocumentNode<T, GraphQLVariables>,
    variables: GraphQLVariables = {},
    optionalContext?: OptionalHeaderContext
  ): ResultSubscription<T> {
    // Extract context from optional context, or set to empty object
    const context = optionalContext && optionalContext.context ? optionalContext.context : {};

    // Subscribe to the query using Apollo Client and return the result as an observable
    return this.apollo.use(serverName).subscribe({
      query,
      context,
      variables,
    });
  }
}
