import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ServicemeshNavigationService } from '@helpers/components/navigation/navigation.service';
import { ServicemeshNavigationItem } from '@helpers/components/navigation/navigation.types';
import { ServicemeshVerticalNavigationBasicItemComponent } from '@helpers/components/navigation/vertical/components/basic/basic.component';
import { ServicemeshVerticalNavigationCollapsableItemComponent } from '@helpers/components/navigation/vertical/components/collapsable/collapsable.component';
import { ServicemeshVerticalNavigationDividerItemComponent } from '@helpers/components/navigation/vertical/components/divider/divider.component';
import { ServicemeshVerticalNavigationSpacerItemComponent } from '@helpers/components/navigation/vertical/components/spacer/spacer.component';
import { ServicemeshVerticalNavigationComponent } from '@helpers/components/navigation/vertical/vertical.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'servicemesh-vertical-navigation-group-item',
  templateUrl: './group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    MatIconModule,
    NgFor,
    ServicemeshVerticalNavigationBasicItemComponent,
    ServicemeshVerticalNavigationCollapsableItemComponent,
    ServicemeshVerticalNavigationDividerItemComponent,
    forwardRef(() => ServicemeshVerticalNavigationGroupItemComponent),
    ServicemeshVerticalNavigationSpacerItemComponent,
  ],
})
export class ServicemeshVerticalNavigationGroupItemComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_autoCollapse: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() autoCollapse: boolean;
  @Input() item: ServicemeshNavigationItem;
  @Input() name: string;

  private _servicemeshVerticalNavigationComponent: ServicemeshVerticalNavigationComponent;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _servicemeshNavigationService: ServicemeshNavigationService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the parent navigation component
    this._servicemeshVerticalNavigationComponent = this._servicemeshNavigationService.getComponent(
      this.name
    );

    // Subscribe to onRefreshed on the navigation component
    this._servicemeshVerticalNavigationComponent.onRefreshed
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}
