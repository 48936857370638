import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { NgModule } from '@angular/core';
import { Apollo, ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { environment } from 'environments/environment';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';

const ACADEMY_GRAPHQL_ENDPOINT = environment.graphql.academy_endpoint;
@NgModule({
  imports: [CommonModule, HttpClientModule, ApolloModule],
  providers: [
    Apollo,
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink): NamedOptions => ({
        //We can set up here the graphql endpoints that we need
        AcademyGraphQLEndpoint: {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: ACADEMY_GRAPHQL_ENDPOINT,
          }),
        },
        AcademyGraphQLEndpointWSS: {
          cache: new InMemoryCache({
            typePolicies: {},
          }),
          link: new GraphQLWsLink(
            createClient({
              url: environment.graphql.academy_endpoint_wss,
              // connectionParams: {
              //     headers: {
              //         Authorization: `Bearer ${sessionStorage.getItem(
              //             'accessToken'
              //         )}`,
              //     },
              // },
            })
          ),
        },
      }),
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
